import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import api from "state/api";
import "react-datepicker/dist/react-datepicker.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "./orders.css";
import DatePicker from "react-datepicker";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AddOrderDialog from "./addDialog";
import EditOrderDialog from "./editDialog";

import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";

const Orders = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [users, setUsers] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [products, setProducts] = useState([]);
  const [promoCodes, setPromoCodes] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Filters
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedPromoCodes, setSelectedPromoCodes] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // Dialog
  const [selectedOrderData, setSelectedOrderData] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [addNewOrderDialogOpen, setAddNewOrderDialogOpen] = useState(false);
  const [deletingOrderId, setDeletingOrderId] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        const response = await api.getOrders();
        setOrders(response.order || []);
        setUsers(response.users || []);
        setShippingAddresses(response.shippingAddress || []);
        setPromoCodes(response.promoCode || []);
        setProducts(response.product || []);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleAddClick = () => {
    setAddNewOrderDialogOpen(true);
  };
  const handleAddNewOrderSubmit = async (data) => {
    try {
      setLoading(true);
      await api.addOrder(data);
      const response = await api.getOrders();
      setOrders(response.order);

      setAlert({
        open: true,
        message: "Oder Added successfully!",
        severity: "success",
      });

      setAddNewOrderDialogOpen(false);
    } catch (error) {
      setAlert({
        open: true,
        message: "Failed to update order. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleEditClick = (id) => {
    const order = orders.find((order) => order.id === id);
    setSelectedOrderData(order);
    setEditDialogOpen(true);
  };
  const handleEditSubmit = async (editedData) => {
    try {
      setLoading(true);
      await api.updateOrder(editedData);
      const response = await api.getOrders();
      console.log("API response:", response);
      setOrders(response.order);

      setAlert({
        open: true,
        message: "Order updated successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error submitting edited data:", error);
      setAlert({
        open: true,
        message: "Failed to update order. Please try again.",
        severity: "error",
      });
    } finally {
      setSelectedOrderData(null);
      setLoading(false);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleDelete = (orderId) => {
    setDeletingOrderId(orderId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      setLoading(true);
      setDeleteDialogOpen(false);
      await api.removeOrder(deletingOrderId);
      const response = await api.getOrders();
      setOrders(response.order);
      setAlert({
        open: true,
        message: "Order deleted successfully!",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting order:", error);
      setAlert({
        open: true,
        message: "Failed to delete order. Please try again.",
        severity: "error",
      });
    } finally {
      setDeletingOrderId(null);
      setLoading(false);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeletingOrderId(null);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleResetFilters = () => {
    setSelectedUsers([]);
    setSelectedPromoCodes("");
    setSelectedPayment("");
    setSelectedStatus("");
    setFromDate(null);
    setToDate(null);
  };

  const handleReceiptDownload = (orderId) => {
    navigate(`/receipt/${orderId}`);
  };

  const usersMap = useMemo(() => {
    return users.reduce((map, user) => {
      map[user.id] = user;
      return map;
    }, {});
  }, [users]);

  const addressMap = useMemo(() => {
    return shippingAddresses.reduce((map, address) => {
      map[address.id] = address;
      return map;
    }, {});
  }, [shippingAddresses]);

  const promoCodesMap = useMemo(() => {
    return promoCodes.reduce((map, promoCode) => {
      map[promoCode.id] = promoCode;
      return map;
    }, {});
  }, [promoCodes]);

  const columns = [
    { field: "id", headerName: "Order ID", width: 100 },

    {
      field: "user_id",
      headerName: "User Or Guest",
      width: 150,
      valueGetter: (params) => {
        const user = usersMap[params.row.user_id];
        return user ? `${user.first_name} ${user.last_name}` : "Guest";
      },
      renderCell: (params) => {
        const user = usersMap[params.row.user_id];
        return user ? `${user.first_name} ${user.last_name}` : "Guest";
      },
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 150,
      valueGetter: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? `${address.first_name} ${address.last_name}` : "";
      },
      renderCell: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? `${address.first_name} ${address.last_name}` : "";
      },
    },
    {
      field: "customer_email",
      headerName: "Customer Email",
      width: 200,
      valueGetter: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? address.email : "";
      },
      renderCell: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? `${address.email}` : "";
      },
    },
    {
      field: "customer_phone",
      headerName: "Customer Phone",
      width: 200,
      valueGetter: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? address.phone : "";
      },
      renderCell: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? `${address.phone}` : "";
      },
    },
    {
      field: "customer_address",
      headerName: "Customer Address",
      width: 400,
      valueGetter: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? address.address : "";
      },
      renderCell: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? address.address : "";
      },
    },
    {
      field: "customer_country",
      headerName: "Customer Country",
      width: 150,
      valueGetter: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? address.country : "";
      },
      renderCell: (params) => {
        const address = addressMap[params.row.shipping_address_id];
        return address ? address.country : "";
      },
    },
    { field: "subtotal", headerName: "Subtotal", width: 100 },
    { field: "shipping_fees", headerName: "Shipping Fees", width: 120 },
    {
      field: "promo_code",
      headerName: "Promo Code",
      width: 150,
      valueGetter: (params) => {
        const promocode = promoCodesMap[params.row.promocode_id];
        return promocode ? `${promocode.code} ${promocode.value}` : "";
      },
      renderCell: (params) => {
        const promocode = promoCodesMap[params.row.promocode_id];
        return promocode ? `${promocode.code} ${promocode.value}` : "";
      },
    },
    { field: "promocode_price", headerName: "Promo Code Price", width: 140 },

    { field: "points", headerName: "Points", width: 100 },
    { field: "total_price", headerName: "Total Price", width: 120 },
    {
      field: "is_paid",
      headerName: "Paid",
      width: 120,
      valueGetter: (params) => (params.row.is_paid === 1 ? "Paid" : "Not Paid"),

      renderCell: (params) => (params.value === 1 ? "Paid" : "Not Paid"),
    },

    {
      field: "payment_method",
      headerName: "Payment Method",
      width: 120,
    },

    {
      field: "tamara_order_id",
      headerName: "Tamara Order Id",
      width: 120,
    },
    { field: "status", headerName: "Status", width: 120 },
    {
      field: "created_at",
      headerName: "Created At",
      width: 160,
      valueGetter: (params) => {
        const date = new Date(params.row.created_at);
        return date.toISOString().slice(0, 16).replace("T", " ");
      },
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toISOString().slice(0, 16).replace("T", " ");
      },
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      width: 160,
      valueGetter: (params) => {
        const date = new Date(params.row.updated_at);
        return date.toISOString().slice(0, 16).replace("T", " ");
      },
      renderCell: (params) => {
        const date = new Date(params.value);
        return date.toISOString().slice(0, 16).replace("T", " ");
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            color="secondary"
            onClick={() => handleEditClick(params.row.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() => handleDelete([params.row.id])}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => handleReceiptDownload(params.row.id)}
          >
            <FileDownloadIcon />
          </IconButton>
        </>
      ),
    },
  ];
  const filteredOrders = useMemo(() => {
    return orders.filter((order) => {
      const orderDate = new Date(order.created_at);
      return (
        (selectedUsers.length === 0 ||
          selectedUsers.includes(order.user_id) ||
          (selectedUsers.includes("guest") && !order.user_id)) &&
        (!selectedPromoCodes || order.promocode_id === selectedPromoCodes) &&
        (selectedPayment === "" ||
          order.is_paid === parseInt(selectedPayment)) &&
        (selectedStatus === "" || order.status === selectedStatus) &&
        (!fromDate || orderDate >= fromDate) &&
        (!toDate || orderDate <= toDate)
      );
    });
  }, [
    orders,
    selectedUsers,
    selectedPromoCodes,
    selectedPayment,
    selectedStatus,
    fromDate,
    toDate,
  ]);

  return (
    <div className="container">
      <Box>
        <FormControl>
          <InputLabel>User</InputLabel>
          <Select
            multiple
            value={selectedUsers}
            onChange={(e) => setSelectedUsers(e.target.value)}
            renderValue={(selected) =>
              selected
                .map(
                  (userId) =>
                    users.find((user) => user.id === userId)?.name || "Guest"
                )
                .join(", ")
            }
          >
            <MenuItem value="guest">Guest</MenuItem>
            {users.map((user) => (
              <MenuItem key={user.id} value={user.id}>
                {user.first_name} {user.last_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Promo Code</InputLabel>
          <Select
            value={selectedPromoCodes}
            onChange={(e) => setSelectedPromoCodes(e.target.value)}
            renderValue={(selected) =>
              Array.isArray(selected)
                ? selected
                    .map(
                      (id) =>
                        promoCodes.find((promoCode) => promoCode.id === id)
                          ?.code || ""
                    )
                    .join(", ")
                : promoCodes.find((promoCode) => promoCode.id === selected)
                    ?.code || ""
            }
          >
            {promoCodes.map((single) => (
              <MenuItem key={single.id} value={single.id}>
                {single.code} - {single.value}%
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Payment</InputLabel>
          <Select
            value={selectedPayment}
            onChange={(e) => setSelectedPayment(e.target.value)}
          >
            <MenuItem value="1">Paid</MenuItem>
            <MenuItem value="0">Not Paid</MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel>Status</InputLabel>
          <Select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Approved">Approved</MenuItem>
            <MenuItem value="Shipped">Shipped</MenuItem>
            <MenuItem value="Received">Received</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
            <MenuItem value="Returned Damaged">Returned Damaged</MenuItem>
            <MenuItem value="Returned">Returned</MenuItem>
            <MenuItem value="Cancel">Cancel</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          placeholderText="Select start date"
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          placeholderText="Start Date"
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleResetFilters}
          style={{ marginLeft: "8px", marginTop: "8px" }}
        >
          Reset Filters
        </Button>
        <Button variant="contained" color="primary" onClick={handleAddClick}>
          <AddIcon /> Add New Order
        </Button>
      </Box>

      <DataGrid
        rows={filteredOrders}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 100 },
          },
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        checkboxSelection
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            color: "#333",
            fontWeight: "bold",
            fontSize: "14px",
          },
          "& .MuiDataGrid-columnHeader": {
            borderRight: "1px solid #e0e0e0",
          },
          "& .MuiDataGrid-toolbarContainer": {
            backgroundColor: "grey",
          },
        }}
        sortingOrder={["asc", "desc"]}
        loading={loading}
      />

      <AddOrderDialog
        isOpen={addNewOrderDialogOpen}
        onClose={() => setAddNewOrderDialogOpen(false)}
        onSubmit={handleAddNewOrderSubmit}
        users={users}
        shippingAddresses={shippingAddresses}
        promoCodes={promoCodes}
        products={products}
      />

      <EditOrderDialog
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        orderData={selectedOrderData}
        onSubmit={handleEditSubmit}
      />

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel}>
        <DialogContent className="dialog-content">
          <Typography style={{ fontSize: "1.2rem" }}>
            Are you sure you want to delete this order?
          </Typography>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <Button
            onClick={handleDeleteCancel}
            variant="outlined"
            style={{
              color: "#ffd700",
              borderColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirmation}
            variant="outlined"
            style={{
              color: "#ffd700",
              borderColor: "#ffd700",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Orders;
